import React, { useCallback } from 'react';
import { Input, Button, Form } from 'antd';
import QueueAnim from 'rc-queue-anim';
import SEO from 'components/SEO';
import { Link } from 'gatsby-plugin-react-i18next';
import { useTranslation } from 'react-i18next';
import startCase from 'lodash/startCase';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from 'redux/Auth/slice';
import { forgotPasswordLoadingSelector } from 'redux/Auth/selector';
import AuthLayout from 'components/Layout/AuthLayout';

function ForgotPasswordPage() {
  const [form] = Form.useForm();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector(forgotPasswordLoadingSelector);

  const handleFinish = useCallback(
    (values) => {
      dispatch(forgotPassword(values));
    },
    [dispatch]
  );

  return (
    <AuthLayout>
      <SEO title={t('forgotPassword')} description={t('SEODescription')} />
      <QueueAnim
        className="auth-banner-content-wrapper"
        delay={300}
        ease="easeOutQuart"
      >
        <h1 key="1">{startCase(t('forgotPassword'))}</h1>
        <h3 key="2">{t('forgotPasswordDesc1')}</h3>
        <p key="3">{t('forgotPasswordDesc2')}</p>
        <Form key="4" onFinish={handleFinish} className="auth-form" form={form}>
          <Form.Item
            name="email"
            rules={[
              {
                type: 'email',
                message: t('invalidEmail'),
              },
              {
                required: true,
                message: t('requiredEmail'),
              },
            ]}
          >
            <Input
              type="email"
              addonBefore={t('email')}
              placeholder="johndoe@example.com"
            />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              className="full-width"
              size="large"
              loading={loading}
            >
              {t('send')}
            </Button>
            {t('alreadyAccount')}
            <Link to="/auth/sign-in">{t('signIn')}</Link>
          </Form.Item>
        </Form>
      </QueueAnim>
    </AuthLayout>
  );
}

export default React.memo(ForgotPasswordPage);
